import * as Yup from "yup";

import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import Alert from "@mui/material/Alert";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowRight from "@mui/icons-material/ArrowRight";
import CalendarToday from "@mui/icons-material/CalendarToday";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Info from "@mui/icons-material/Info";
import LocalOffer from "@mui/icons-material/LocalOffer";
import Print from "@mui/icons-material/Print";
import { registerForYardSale } from "../services/firebase";
import { useFormik } from "formik";

const PhoneInput = ({ value, onChange, onBlur, error, helperText }) => {
  const handleChange = (e) => {
    const formatter = new AsYouType("US");
    const formattedValue = formatter.input(e.target.value);
    onChange(formattedValue);
  };

  return (
    <TextField
      label="Phone Number"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      fullWidth
      margin="normal"
      placeholder="(555) 555-5555"
      required
    />
  );
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .test("valid-phone", "Invalid phone number", (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, "US");
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  items: Yup.string(),
  paymenttype: Yup.string().required("Payment type is required"),
  paymentInfo: Yup.string().required("Payment info is required"),
});

const YardSaleSignup = () => {
  const [sellerCode, setSellerCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      items: "",
      paymenttype: "venmo",
      paymentInfo: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        // Parse the phone number to E.164 format for consistent storage
        const phoneNumber = parsePhoneNumberFromString(values.phone, "US");
        const formattedPhone = phoneNumber
          ? phoneNumber.format("E.164")
          : values.phone;

        const response = await registerForYardSale(
          values.name,
          formattedPhone,
          values.email,
          values.items.split("\n"),
          values.paymenttype,
          values.paymentInfo
        );
        setSellerCode(response.sellerCode);
        setSnackbar({
          open: true,
          message: "Successfully registered!",
          severity: "success",
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Failed to register. Please try again.",
          severity: "error",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Community Yard Sale Sign-Up
      </Typography>
      <Box
        component="img"
        src="https://firebasestorage.googleapis.com/v0/b/manageyourclub-973b2.appspot.com/o/clubs%2Fcola-stl%2Fproducts%2FoNN4ny9_qjQGFuWPm7UkP.png?alt=media&token=a268ce60-3ce4-4a1b-abaf-3334ecf3b00e"
        alt="Community Yard Sale at The Cola"
        width="100%"
        height="auto"
        my={2}
      />
      {sellerCode ? (
        <Paper elevation={3} sx={{ p: 3, mt: 3, textAlign: "center" }}>
          <CheckCircle color="success" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h5" gutterBottom>
            Registration Successful!
          </Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            Your Seller Code: {sellerCode}
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for registering for our community yard sale. Please keep
            this code for your records.
          </Typography>

          <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Next Steps:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Print color="primary" />
                </ListItemIcon>
                <ListItemText primary="Print or save this page for your reference." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalOffer color="primary" />
                </ListItemIcon>
                <ListItemText primary="Prepare your items and price them. You can print your own price stickers with your seller code or we can provide them!" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CalendarToday color="primary" />
                </ListItemIcon>
                <ListItemText primary="Mark your calendar for item drop-off on 23 August 2024 after 10 AM" />
              </ListItem>
            </List>
          </Box>

          <Typography variant="h5" paragraph>
            Sale Day: 24 August 2024 @ 8 AM
          </Typography>

          <Typography variant="body2" paragraph>
            We'll email you more details about the event, including drop-off
            instructions and any updates.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            onClick={() => {
              window.location.href = "/events/community-yard-sale";
            }}
          >
            Return to Home
          </Button>
        </Paper>
      ) : (
        <>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              <Info color="primary" sx={{ mr: 1, verticalAlign: "middle" }} />
              Important Information
            </Typography>
            <Typography variant="body2" paragraph>
              Thank you for your interest in participating in our community yard
              sale! Please fill out the form below to register as a seller.
            </Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <ArrowRight color="primary" />
                </ListItemIcon>
                <ListItemText primary="You will receive a seller number to track your sales." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRight color="primary" />
                </ListItemIcon>
                <ListItemText primary="Drop off your items at The Cola on the day before the event." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRight color="primary" />
                </ListItemIcon>
                <ListItemText primary="You do not have to be present to sell. We will handle the sales for you." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRight color="primary" />
                </ListItemIcon>
                <ListItemText primary="We will provide pricing stickers with your unique seller number." />
              </ListItem>
            </List>
          </Paper>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              margin="normal"
              required
            />
            <PhoneInput
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue("phone", value)}
              onBlur={formik.handleBlur("phone")}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              margin="normal"
              required
            />

            <Divider sx={{ my: 2 }} />
            <Typography variant="body2">
              Please provide your preferred payment method and username for
              receiving funds from your sales.
            </Typography>
            <Select
              name="paymenttype"
              label="Payment Method"
              value={formik.values.paymenttype}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.paymenttype && Boolean(formik.errors.paymenttype)
              }
              fullWidth
              margin="normal"
              required
            >
              <MenuItem value="venmo">Venmo</MenuItem>
              <MenuItem value="cashapp">CashApp</MenuItem>
              <MenuItem value="paypal">PayPal</MenuItem>
              <MenuItem value="check">Check</MenuItem>
            </Select>
            <TextField
              label="Payment Username"
              name="paymentInfo"
              value={formik.values.paymentInfo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.paymentInfo && Boolean(formik.errors.paymentInfo)
              }
              helperText={
                formik.touched.paymentInfo && formik.errors.paymentInfo
              }
              fullWidth
              margin="normal"
              placeholder="@username"
            />

            <Divider sx={{ my: 2 }} />

            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Items for Sale
              </Typography>
              <Typography variant="body2" paragraph>
                For our planning purposes, let us know what items you plan to
                sell. One item/category per line.
              </Typography>
              <Paper elevation={2} sx={{ p: 2, bgcolor: "grey.100" }}>
                <Typography variant="body2" component="div">
                  For example:
                  <List dense>
                    <ListItem>
                      <ListItemText primary="clothes" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="toys" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="books" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="a mint 1996 Ford Mustang GT with 50,000 miles" />
                    </ListItem>
                  </List>
                </Typography>
              </Paper>
            </Box>

            <TextField
              label="List of Items (Optional)"
              name="items"
              value={formik.values.items}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.items && Boolean(formik.errors.items)}
              helperText={formik.touched.items && formik.errors.items}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />

            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Sign Up"}
              </Button>
            </Box>
          </form>
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default YardSaleSignup;
