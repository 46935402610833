import { RouterProvider, createBrowserRouter } from "react-router-dom";

import CheckedIn from "./screens/CheckedIn";
import { Checkout } from "./screens/Checkout";
import { Claim } from "./screens/Claim";
import { ContactUs } from "./screens/ContactUs";
import { DataDeletion } from "./screens/DataDeletion";
import Error from "./components/Error";
import { ErrorBoundary } from "react-error-boundary";
import { EventDetail } from "./screens/EventDetail";
import { EventsList } from "./screens/EventsList";
import Home from "./screens/Home";
import { Invite } from "./screens/Invite";
import Landing from "./screens/Landing";
import { Layout } from "./components/Layout";
import { NotFound } from "./screens/NotFound";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { Profile } from "./screens/Profile";
import ReceiptScreen from "./screens/ReceiptScreen";
import { Share } from "./screens/Share";
import SignInScreen from "./screens/SignIn";
import { SignOutPage } from "./screens/SignOut";
import ThankYouPage from "./screens/ThankYouPage";
import { Waiver } from "./components/Waiver";
import YardSaleSignup from "./screens/YardSale";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "checkout", element: <Checkout /> },
      { path: "profile", element: <Profile /> },
      { path: "checked-in", element: <CheckedIn /> },
      { path: "thank-you", element: <ThankYouPage /> },
      { path: "privacy", element: <PrivacyPolicy /> },
      { path: "data-deletion", element: <DataDeletion /> },
      { path: "receipt/:orderId", element: <ReceiptScreen /> },
      { path: "terms", element: <Waiver /> },
      { path: "contact", element: <ContactUs /> },
      { path: "invite/:invitation", element: <Invite /> },
      { path: "events/:eventSlug", element: <EventDetail /> },
      { path: "events", element: <EventsList /> },
      { path: "share", element: <Share /> },
      { path: "share/:code", element: <Claim /> },
    ],
  },
  { path: "signout", element: <SignOutPage /> },
  { path: "signin", element: <SignInScreen /> },
  { path: "/landing", element: <Landing /> },
  { path: "yardsale", element: <YardSaleSignup /> },
  { path: "*", element: <NotFound /> },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
  return (
    <ErrorBoundary FallBackComponent={Error}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}
