import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getSharedPasses, claimPassByLink } from "./../services/firebase";
import { useState } from "react";
import { AppContext } from "../context/AppContext";
import { Box, Button, Typography } from "@mui/material";

const unclaimedPasses = (passes) => {
  return passes.filter((pass) => pass.status === "shared");
};
const claimedPasses = (passes) => {
  return passes.filter((pass) => pass.status !== "shared");
};

export const Claim = () => {
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passes, setPasses] = useState([]);
  const { member } = useContext(AppContext);

  const claimPass = () => {
    claimPassByLink(code).then(({ data }) => {
      if (data.error) {
        setError(data.error.message);
        return;
      }
      setPasses(data);
    });
    // TODO: handle error
  };

  useEffect(() => {
    setError(null);
    if (code) {
      setLoading(true);
      getSharedPasses(code).then(({ data }) => {
        console.log({ data });
        if (data?.error) {
          setError(data.error.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        setPasses(data);
      });
    }
  }, [code]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return <h1>{error}</h1>;
  }
  if (passes.length === 0) {
    return <h1>No passes available</h1>;
  }

  const unclaimed = unclaimedPasses(passes);
  const claimed = claimedPasses(passes);

  if (unclaimed.length === 0) {
    return <h1>All passes claimed</h1>;
  }
  // if this member has already claimed a pass from the group
  if (claimed.length > 0) {
    const clamedByMe = claimed.filter((pass) => pass.memberId === member?.id);
    if (clamedByMe.length > 0) {
      return <h1>You've claimed your pass! Have Fun!</h1>;
    }
  }

  // otherwise, show the claim form
  return (
    <Box>
      <Typography variant="h4">Claim a pass</Typography>
      <Typography variant="body1">
        You have been invited to claim a pass. Click the button below to claim
        your pass.
      </Typography>
      <Button variant="contained" onClick={claimPass}>
        Claim Your Pass
      </Button>
    </Box>
  );
};
